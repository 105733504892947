<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">其它问题</div>
      <div class="topRight"></div>
    </div>
    <div class="typeBox">
      <div><span>*</span>反馈类型</div>
      <div @click="economize = true">
        <input
          type="text"
          v-model="economizeVlue"
          placeholder="请选择反馈类型"
        />
        <img src="../../../image/quanz/xl.png" alt="" />
      </div>
    </div>
    <div class="problemBox">
      <div><span>*</span>问题描述</div>
      <div>
        <el-input
          id="inpTextarea"
          type="textarea"
          placeholder="请输入内容"
          v-model="recommend.content"
          maxlength="200"
          show-word-limit
        ></el-input>
      </div>
    </div>
    <div class="UploadBox">
      <div>相关图片（{{ recommend.imgs.length }}/3）</div>
      <div>
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          :max-count="3"
          multiple
          @delete="onDelete"
        ></van-uploader>
      </div>
    </div>
    <div class="contactBox">
      <div>联系方式</div>
      <input
        type="text"
        v-model="recommend.contact"
        placeholder="请输入邮件、手机号、QQ号或者微信号"
      />
    </div>
    <div class="submitBtn" @click="submitEvent()">提交反馈</div>
    <van-popup v-model="economize" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="economize = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { attUpload, recommendAdd } from "../../../api/circle";
import { Toast } from "vant";
export default {
  data() {
    return {
      economize: false,
      economizeVlue: "",
      columns: [
        {
          id: 0,
          name: "产品体验问题",
        },
        {
          id: 1,
          name: "内容问题",
        },
        {
          id: 2,
          name: "其它问题",
        },
      ],
      fileList: [],
      recommend: {
        type: "", //意见类型
        content: "", //问题描述
        imgs: [], //上传附件
        contact: "", //联系方式
      },
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 反馈类型
    onConfirm(value) {
      console.log(value);
      this.economizeVlue = value.name;
      this.recommend.type = value.id;
      this.economize = false;
    },
    // 上传附件
    afterRead(file) {
      let formData = new FormData();
      if (file.length > 1) {
        file.forEach((item) => {
          formData.append("files", item.file);
        });
      } else {
        formData.append("files", file.file);
      }
      attUpload(formData).then((res) => {
        console.log(res, "宣传图");
        if (res.data.code != 0) {
          Toast(res.data.msg);
        } else {
          res.data.data.forEach((item) => {
            this.recommend.imgs.push(item);
          });
        }
      });
    },
    // 删除附件
    onDelete(file) {
      // console.log(file, "......");
      // const index = this.recommend.imgs.indexOf(file);
      // if (index > -1) {
      //   this.recommend.imgs.splice(index, 1);
      // }
      this.recommend.imgs.forEach(function (item, index, array) {
        console.log(index);
        array.splice(index, 1);
        this.fileList.splice(index, 1);
      });
    },
    // 提价反馈
    submitEvent() {
      this.recommend.imgs = JSON.stringify(this.recommend.imgs);
      recommendAdd(this.recommend).then((res) => {
        console.log(res, "提交");
        if (res.data.code === 0) {
          Toast("提交成功");
          this.$router.push({ path: "/feedbackPage" });
        } else {
          Toast(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.setBox {
  background: #f6f8fa;
  height: 100vh;
  margin-bottom: 36px;
}
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 18px; */
  background: #fff;
  padding-top: 18px;
  padding-bottom: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.typeBox {
  width: 686px;
  height: 224px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
}
.typeBox div:nth-child(1),
.problemBox div:nth-child(1),
.UploadBox div:nth-child(1) {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.typeBox div:nth-child(1) span,
.problemBox div:nth-child(1) span {
  display: inline-block;
  color: #fa2819;
  margin-top: 32px;
  margin-left: 32px;
}
.typeBox div:nth-child(2) {
  width: 622px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  background: #f6f8fa;
  margin-left: 32px;
  margin-top: 32px;
  border-radius: 16px;
}
.typeBox div:nth-child(2) input {
  width: 400px;
  height: 60px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  border: none;
  background: #f6f8fa;
  margin-left: 26px;
}
.typeBox div:nth-child(2) img {
  width: 36px;
  height: 36px;
  margin-right: 26px;
}
.problemBox {
  width: 686px;
  height: 492px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
}
.problemBox div:nth-child(2) {
  margin-left: 32px;
  margin-top: 32px;
}
/deep/.el-input__count {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  background: #f6f8fa;
  position: absolute;
  bottom: 50px;
  right: 55px;
}
/deep/#inpTextarea {
  width: 622px;
  height: 356px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  border: none;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 32px;
}

.UploadBox {
  width: 686px;
  min-height: 334px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
  overflow: hidden;
}
.UploadBox div:nth-child(1) {
  margin-left: 32px;
  margin-top: 30px;
}

/deep/.van-uploader__preview-image {
  width: 198px;
  height: 198px;
}

/deep/.van-uploader__upload {
  width: 198px;
  height: 198px;
}
.contactBox {
  width: 686px;
  height: 260px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 32px;
  margin-left: 32px;
  overflow: hidden;
}
.contactBox div:nth-child(1) {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-top: 32px;
  margin-left: 32px;
}
.contactBox input {
  width: 622px;
  height: 80px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: none;
  margin-left: 32px;
  margin-top: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  padding-left: 26px;
  box-sizing: border-box;
}
.submitBtn {
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 88px;
  margin-left: 32px;
  margin-top: 32px;
}
</style>
